import React from "react";
import Seo from "components/common/seo";
import PositionDescription from "./components/position-description";
import PositionTop from "components/pages/careers/components/top";
import PositionApply from "./components/position-apply";
import { graphql } from "gatsby";
import { PositionContainer } from "./styles";
import { PositionProps } from "./types";
import { Helmet } from "react-helmet";

const Position: React.FC<PositionProps> = ({ data: { position } }) => {
  const description = position.details[0].value.replaceAll(/(<([^>]+)>)/gi, "");
  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`window.comeetInit = function() {
            COMEET.init({
            "token": "${process.env.GATSBY_COMEET_TOKEN}",
            "company-uid": "${process.env.GATSBY_COMEET_UID}",
            "position-uid": "${position.uid}",
            "candidate-source-storage": false,
            "color": "#4726D1",
            });
          };
          (function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) {return; } js = d.createElement(s); js.id = id;
          js.src = "//www.comeet.co/careers-api/api.js"; fjs.parentNode.insertBefore(js, fjs); }(document, 'script', 'comeet-jsapi'));`}
        </script>
        <script>{`(function(){var a=function(){window.COMEET.set("candidate-source-storage",!0)};window.COMEET?a():window.comeetUpdate=a})();`}</script>
      </Helmet>

      <Seo title={position.name} description={description} />
      <PositionContainer className="position-container">
        <PositionTop isPosition />

        <div className="application-content">
          <PositionDescription position={position} />
          <PositionApply uid={position.uid} />
        </div>
      </PositionContainer>
    </>
  );
};

export default Position;

export const query = graphql`
  query Position($uid: String!) {
    position(uid: { eq: $uid }) {
      department
      uid
      name
      location {
        country
      }
      details {
        name
        value
        order
      }
    }
  }
`;
