import React from "react";
import PositionForm from "./position-form";
import { Typography } from "@mui/material";
import { PositionApplyContainer } from "../styles";

interface PositionApplyProps {
  uid: string;
}

const PositionApply: React.FC<PositionApplyProps> = ({ uid }) => {
  return (
    <PositionApplyContainer className="position-apply">
      <Typography className="position-apply-title" variant="h3">
        Application
      </Typography>
      <PositionForm uid={uid} />
    </PositionApplyContainer>
  );
};

export default PositionApply;
