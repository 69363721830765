import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const PositionApplyContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="div" {...props} />
)`
  &.position-apply {
    border: 1px solid #dddbe3;
    border-radius: ${({ theme }) => theme.spacing(1)};
    display: flex;
    flex-direction: column;
    padding-inline: ${({ theme }) => theme.spacing(1)};
    height: fit-content;
    flex-basis: 40%;
    background: ${({ theme }) => theme.palette.p_white.full};
    min-height: 980px;

    element.style {
    }

    & > .position-apply-title {
      color: ${({ theme }) => theme.palette.primary.main};
      padding: ${({ theme }) => theme.spacing(4, 2, 0, 2)};
      font-weight: bold;
      font-size: 16px;
    }
  }
`;

export const PositionFormContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="form" {...props} />
)`
  &.application-form {
    ${({ theme }) => theme.breakpoints.down("md")} {
    }
  }
`;

export const PositionDescriptionContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="div" {...props} />
)`
  &.position-description {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(6)};
    & > .focus-input {
      position: absolute;
      left: 0;
      top: 20%;
      width: 0;
      height: 0;
      border: none;
      &:focus {
        outline: none;
      }
      ${({ theme }) => theme.breakpoints.down("md")} {
        top: 10%;
      }
    }
    & > .position-description-top {
      & > .tags {
        display: flex;
        align-items: center;
        gap: ${({ theme }) => theme.spacing(2)};
      }
      & > h3 {
        font-weight: 700;
        color: ${({ theme }) => theme.palette.primary.main};
        font-size: 24px;
        line-height: 36px;
        margin-top: 0;
        margin-bottom: ${({ theme }) => theme.spacing(2.75)};
      }
    }

    & > .content-owner {
      display: flex;
      flex-direction: column;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(1)};

      & > .content-container {
        display: flex;
        flex-direction: column;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
        & > p {
          font-size: 18px;
        }
        & > ul {
          padding-left: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
        }
        & > ul,
        p {
          font-size: 14px;
          color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
          display: flex;
          flex-direction: column;
          gap: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
        }
      }
    }
  }
`;
