import React, { useEffect } from "react";
import { PositionFormContainer } from "../../styles";

interface PositionFormProps {
  uid: string;
}

const PositionForm: React.FC<PositionFormProps> = ({ uid }) => {
  const comeetForm = React.createElement("script", {
    type: "comeet-applyform",
    "data-position-uid": uid,
    "data-token": process.env.GATSBY_COMEET_TOKEN,
    "data-company-uid": process.env.GATSBY_COMEET_UID,
    "data-color": "#4726D1",
    "data-button-text": "Apply for this job",
  });

  return (
    <PositionFormContainer className="application-form">
      {comeetForm}
    </PositionFormContainer>
  );
};

export default PositionForm;
