import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const PositionContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="div" {...props} />
))`
  &.position-container {
    min-height: 100vh;
    background: ${({ theme }) => theme.palette.p_pink.background};

    & > .application-content {
      display: flex;
      padding: ${({ theme }) => theme.spacing(8, 10, 10)};
      gap: ${({ theme }) => theme.spacing(15)};
      min-height: 100vh;
      max-width: 1440px;
      margin: 0 auto;
    }

    ${({ theme }) => theme.breakpoints.down("sm")} {
      & > .application-content {
        flex-direction: column;
        padding: ${({ theme }) => theme.spacing(2, 2, 4, 2)};
        gap: ${({ theme }) => theme.spacing(4)};
      }
    }
  }
`;
