import React, { useEffect, useRef } from "react";
import DepartmentTag from "components/pages/careers/components/common/department-tag";
import LocationTag from "components/pages/careers/components/common/location-tag";
import { PositionDescriptionContainer } from "../styles";
import { PositionDescriptionProps } from "../types";
import parse from "html-react-parser";

const PositionDescription: React.FC<PositionDescriptionProps> = ({
  position,
}) => {
  const positionRef = useRef(null);
  useEffect(() => {
    positionRef?.current?.focus();
  }, []);
  return (
    <PositionDescriptionContainer className="position-description">
      <input ref={positionRef} className="focus-input" />
      <div className="position-description-top">
        <h3>{position.name}</h3>
        <div className="tags">
          <LocationTag location={position.location.country} />
          <DepartmentTag department={position.department} />
        </div>
      </div>
      {position.details.map((item, index) => {
        return (
          item.value && (
            <div className="content-owner" key={index}>
              <h4>{item.name}</h4>
              <div className="content-container">{parse(item.value)}</div>
            </div>
          )
        );
      })}
    </PositionDescriptionContainer>
  );
};

export default PositionDescription;
